<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout row wrap :gradient="$vuetify.breakpoint.smAndUp" :primary="$vuetify.breakpoint.xsOnly" :pt-5="$vuetify.breakpoint.smAndUp" :px-5="$vuetify.breakpoint.smAndUp" :text-xs-center="$vuetify.breakpoint.xsOnly">
      <v-flex xs12 max-width-1400 full-width ma-auto>
        <v-layout row wrap v-if="!g_isLoading">
          <v-flex xs12 :px-4="$vuetify.breakpoint.xsOnly" :py-4="$vuetify.breakpoint.xsOnly">
            <v-card color="transparent" flat tile>
              <v-card-text class="pa-0 pb-5">
                <h1 class="graphik-bold font-35 mb-2 mt-0 white--text">{{ $ml.get('thankyou_title') }}</h1>

                <h2 class="graphik-light font-17 mb-4 white--text">{{ $ml.get('thankyou_description') }}</h2>

                <v-layout row wrap>
                  <v-flex xs12 sm6>
                    <v-img
                      v-if="policy.company_internal_name"
                      max-width="250px"
                      contain
                      :class="{'mb-4': $vuetify.breakpoint.smAndUp, 'mb-0 mx-auto': $vuetify.breakpoint.xsOnly}"
                      :src="company_logo"
                      :lazy-src="company_logo"
                    />
                  </v-flex>

                  <v-flex xs12 sm6 :text-xs-right="$vuetify.breakpoint.smAndUp">
                    <p class="graphik-bold font-20 mb-2 mt-5 white--text" v-if="policy.car">{{ policy.car }}</p>
                    <p class="graphik-bold font-20 mb-2 mt-5 white--text" v-else>{{ policy.policy_type }}: {{ policy.policy_type_plan }}</p>
                    <p class="graphik-bold font-20 mb-4 white--text">{{ $ml.get('fields_policy_number') }}: {{ policy.number }}</p>
                  </v-flex>
                </v-layout>

                <v-btn 
                  color="pantene" 
                  depressed :block="$vuetify.breakpoint.xsOnly"
                  :loading="g_isLoading || !document"
                  :class="{'mr-4': $vuetify.breakpoint.smAndUp}"
                  class="px-2 normalcase graphik-bold-italic ma-0 mb-4 border-radius-5 white--text"
                  :disabled="g_isLoading"
                  @click="openLink(document, 'thank_you_cash_download_policy')"
                >{{ $ml.get('fields_download_and_payment') }}</v-btn>

                <v-btn 
                  color="green-cash" 
                  depressed :block="$vuetify.breakpoint.xsOnly"
                  :loading="g_isLoading"
                  :class="{'ml-4': $vuetify.breakpoint.smAndUp}"
                  class="px-2 normalcase graphik-bold-italic ma-0 mb-4 border-radius-5 white--text"
                  :disabled="g_isLoading"
                  @click="openLink(instructions, 'thank_you_cash_download_instructions')"
                >{{ $ml.get('fields_payment_forms') }}</v-btn>

                <h2 class="graphik-light font-17 mb-4 white--text">{{ $ml.get('thankyou_cash_description_1') }}</h2>

                <h2 class="graphik-bold font-17 mb-2 white--text">{{ $ml.get('thankyou_cash_description_2') }}</h2>

                <h2 class="graphik-bold font-17 mb-4 white--text clickable" @click="openLink('https://api.whatsapp.com/send?phone=528119174197', 'goto_whatsapp_cash')"><v-icon color="white" class="line-height-17">fab fa-whatsapp</v-icon> {{ $ml.get('thankyou_cash_phone') }}</h2>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'PayWithCash',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/gracias'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/gracias'
      }
    ]
  },
  data () {
    return {
      policy: {},
      document: ''
    }
  },
  computed: {
    company_logo () {
      if (!this.policy.company_internal_name) {
        return null
      }
      return require('@/assets/img/companies/' + this.policy.company_internal_name + '-white-full.png')
    },
    instructions () {
      let instructions = 'https://media.gorilaseguros.com/manuales/' + this.policy.company_internal_name + '.pdf'
      return instructions
    }
  },
  methods: {
    // CRUD
    getPolicy () {
      this.g_isLoading = true
      this.$api.getPolicy(
        this.$route.params.policy,
        response => {
          this.policy = response.body.data
          this.g_isLoading = false
          this.createDocument()
        },
        response => this.$errorHandling(response).then(() => {
          this.getPolicy()
        }, () => {})
      )
    },
    createDocument () {
      this.$api.createDocument(
        this.$route.params.policy,
        response => {
          this.document = response.body.data.document
        },
        response => this.$errorHandling(response).then(() => {
          this.createDocument()
        }, () => {})
      )
    },
    openLink (url, event) {
      this.$analytics(event)
      window.open(url, '_blank')
    }
  },
  mounted () {
    this.getPolicy()
  }
}
</script>

<style scoped>
  .gradient {
    background-image: linear-gradient(#4C3AA1, #271B4D) !important;
  }

  .auto-height {
    height: auto !important;
  }
</style>
